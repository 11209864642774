.form {
    flex-direction: column;
}

.message {
    width: 60%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
}

.error-div {
    height: 100%;
}

.error-message {
    width: 60%;
    text-align: center;
    align-items: center;
    margin: auto;
    padding-top: 16vh;
}