.ad-container {
    width: 100%;
    margin-bottom: 8px;
    margin-top: 16px;
    font-size: 26px;
}

.img-ad {
    position: relative;
    width: 85%;
    height: 100%;
    align-items: center;
    text-align: center;
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-radius: 10px;
}