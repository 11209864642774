.options {
    margin-top: 32px;
    margin-bottom: 54px;
    justify-content: space-evenly;
}

.options-mobile {
    margin-top: 32px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
}

.login-google {
    display: grid;
    place-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
    margin-bottom: 32px;
}

.or-class {
    margin-bottom: 24px;
}

.or-label-class {
    color: #50C878;
    font-weight: bold;
}

.main-login {
    overflow-y: scroll;
    height: 100vh;
    margin-bottom: 32px;
}

.page-bottom {
    padding-bottom: 80px;
}