.logo {
    font-family: 'Galada', cursive;
    font-size: 32px;
    transition: transform 0.3s ease-in-out;
    color: #50C878;
}

body {
    font-family: 'Inter', sans-serif;
}

h1 {
    font-size: 32px;
    align-items: flex-end;
    color: #50C878;
}

.funky-title {
    font-family: "Sixtyfour", coursive;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 32px;
}

.label-link {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 14px;
    margin-right: 14px;
    font-size: 14px;
}

.subtitle {
    margin-top: 32px;
    color: #50C878;
}

h2 {
    color: #50C878;
}

.thanker-color {
    color: #50C878;
}

.error {
    color: red;
}

.logo:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.header-label {
    transition: transform 0.1s ease-in-out;
}

.header-label:hover {
    transform: scale(1.05);
}