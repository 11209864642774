.container-confirmation {
    border-radius: 10px;
    border-style: solid;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 12vh;
}

.container-confirmation-mobile {
    width: 80%;
}

.container-confirmation-desktop {
    width: 50%;
}

.ok-container {
    border-color: #50C878;
}

.error-container {
    border-color: red;
}