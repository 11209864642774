.modal {
    position: fixed;
    top: auto;
    left: auto;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
  }

  button {
    margin: 4px;
  }