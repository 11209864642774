.no-thanks {
    display: flex;
    flex-direction: column;
    text-align: center; 
    margin-left: 24px;
    margin-right: 24px;
}

.desktop-specs {
    margin-top: 14vh;
}

.mobile-specs {
    margin-top: 96px;
}

.text-empty-thanks {
    margin: 16px;
    font-weight: bold;
}

.text-desktop {
    font-size: 32px;
}

.text-mobile {
    font-size: 18px;
}

.emoji {
    font-size: 64px;
}