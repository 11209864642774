.container {
    display: flex;
    height: 100vh;
}
  
.left {
    flex: 3;
    display: flex;
    align-items: center;
    overflow-y: auto;
    height: 100%;
    flex-direction: column;
}

.right {
    flex: 4;
    overflow-y: scroll;
    height: 100vh;
    justify-content: center;
    text-align: center;
}

.right::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.quote {
    flex: 2;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    overflow-y: hidden;
    padding-bottom: 3vh;
}

.info-icon {
    width: 15px;
    display: grid;
    place-items: center;
}

.privacy {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.privacy-select {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 8px;
}

.page-padding {
    padding-top: 24px;
}

.thanks-container {
    padding-top: 2vh;
    margin-bottom: 100px;
}

.user-container-mobile {
    margin-bottom: 100px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.thanks-form {
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    transform: translate(-50%, -50%);
    z-index: 1000;
    pointer-events: none;
}

.mobile-container-user-page {
    position: relative;
}

.center-loader {
    top: 20%;
    left: 80%;
    margin: 8px;
    margin-bottom: 40px;
}

.text-profile {
    margin: 16px;
    font-size: 12px;
    font-weight: bold;
    color: #50C878;
}

.mobile-user {
    flex-direction: column;
    align-items: center;
    animation: fadeIn 1.5s ease-in-out forwards;
    overflow-y: scroll;
}

.top-padding-mobile {
    padding-top: 2vh;
}

.bottom-padding-mobile {
    padding-bottom: 80px;
}

.arrow {
    position: absolute;
    margin: 16px;
    z-index: 10;
    top: 30%;
    cursor: pointer;
    display: block;
    flex-direction: column;
}

.right-arrow-font {
    font-size: 12px;
    padding-top: 4px;
    text-align: left;
    color: #50C878;
}

.arrow-specs {
    width: 56px;
    height: 56px;
}

.right-arrow {
    right: 2%;
    text-align: end;
}

.left-arrow {
    right: 2%;
    transform: rotate(-0.5turn);
    opacity: 0.7;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.user-page-search {
    margin-bottom: 16px;
}

.loader-search-users {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2000;
}

.ad {
    margin-top: 24px;
}