.password-container {
    width: 100%;
    position: relative;
    flex-grow: 1;
}

.eye-div {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    cursor: pointer;
}

.eye {
    width: 20%;
    height: 20%;
    right: 0;
}