.following-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    overflow-y: hidden;
}

.search-container {
    width: 100%;
    height: 40px;
    position: fixed;
    z-index: 1000;
    left: 0;
}

.search-following {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
}

.users-container {
    margin-top: 6vh;
    z-index: 1;
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 22vh;
}

.user-items {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 8px;
    padding-top: 16px;
}

.top-padding-following {
    padding-top: 16vh;
}

.no-following {
    padding-top: 24vh;
    font-weight: bold;
    text-align: center;
    align-items: center;
}

.loader-search-users {
    position: absolute;
    margin-top: 24px;
    left: 0;
    right: 0;
    z-index: 2000;
}