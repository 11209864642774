.thanks-card-container {
    display: flex;
    flex-direction: column;
}

.card-thanks {
    margin-bottom: 16px;
    margin-right: auto;
    margin-left: auto;
    padding: 16px;
    border-radius: 8px;
    color: white;
    text-align: center;
    width: 85%;
    display: flex;
    justify-content: center; 
    opacity: 0;
    animation: fadeIn 1.5s ease-in-out forwards;
    position: relative;
}

.private-thanks-color {
    background-color: #29AB87;
}

.public-thanks-color {
    background-color: #50C878;
}

.giver-thanks-card {
    flex: 1;
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content-thanks-card {
    flex: 10;
    text-align: left;
    flex-direction: column;
    display: flex;
    justify-content: center;
    overflow-wrap: break-word;
    word-break: break-word;
}

.thanks-content {
    display: flex;
    flex-direction: column;
}

.card-element {
    padding: 2px;
    width: 100%;
    padding-left: 8px;
    padding-right: 4px;
}

.text {
    padding-bottom: 4px;
    overflow-wrap: break-word; 
    text-align: start;
}

.date {
    font-size: 12px;
}

.bottom-thanks-container {
    display: block;
    justify-content: start;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.circle-thanks {
    border-radius: 50%;
    overflow: hidden;
}

.thanks-card-size {
    width: 56px;
    height: 56px;
}

.thanks-card-size-mobile {
    width: 40px;
    height: 40px;
}

.user-search-size {
    width: 32px;
    height: 32px;
}

.circle-thanks img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.privacy-box {
    margin-top: 12px;
    width: 100%;
}

.select-thanks-privacy {
    text-align: start;
    display: flex;
    justify-content: start;
    margin-left: 0px;
    margin-bottom: 0px;
    padding: 2px;
}

.clickable:hover {
    cursor: pointer;
}

.delete-container {
    position: relative;
    top: 0;
    right: 0;
}

.delete-icon {
    width: 24px;
    height: 24px;
}

.delete-icon:hover {
    cursor: pointer;
}

.text-label {
    margin-left: 0px;
    font-size: 12px;
    color: #471F44;
}

.text-label:hover {
    font-weight: bold;
}

.lock {
    position: absolute;
    right: 2px;
    bottom: 2px;
    padding: 8px;
    padding-right: 16px;
}

.lock-img {
    width: 16px;
    height: 16px;
}

.send-email {
    width: 40px;
    position: absolute;
    right: 2px;
    bottom: 2px;
    padding: 8px;
    margin-right: 48px;
}

.send-email:hover {
    cursor: pointer;
    transform: scale(1.15);
    transition: transform 0.1s ease-in-out;
}