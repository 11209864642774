.icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
}

.icon-container:hover {
    cursor: pointer;
    font-weight: bold;
}

label {
    font-size: 16px;
    color: #2d2d2d;
}