.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #50C878; 
    border-radius: 50%;
    animation: spin 2s linear infinite;
    width: 100%;
  }

  .big {
    width: 250px;
    height: 250px;
  }

  .small {
    width: 50px;
    height: 50px;
  }

  .massive {
    width: 70vh;
    height: 70vh;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .loader-container {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .desktop {  
    
  }

.mobile-loader {
  left: 0 !important;
}

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }