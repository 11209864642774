.label-create-error {
    color: red;
    font-size: 10px;
    width: 40%; /* Set the width of the label to 40% of its parent container */
    display: inline-block; /* or 'block' depending on your layout needs */
    text-align: left;
}

label {
    color: #50C878;
}

.center {
    margin: auto;
    text-align: center;
}

.success-message {
    padding-top: 12vh;
    font-size: 24px;
    font-weight: bold;
    color: #50C878
}