.user-item {
    margin: 8px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 4px;
}

.user-container {
    display: grid;
    place-items: center;
    margin-bottom: 0px;
    margin-left: 16px;
    margin-right: 16px;
    color: #2d2d2d;
}

.circle {
    border-radius: 50%;
    overflow: hidden;
    width: 140px;
    height: 140px;
}

.some-margin-bottom {
    margin-bottom: 8px;
}

.page-size {
    width: 64px;
    height: 64px;
}

.page-size-mobile {
    width: 32px;
    height: 32px;
}

.circle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.user-data {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    margin-top: 16px;
}

.submit-button:active {
    background-color: #50C878;
    color: white;
    box-shadow: 0 0 0 0 rgb(255, 255, 255);
}

.submit-button:hover {
    box-shadow: 0 0px 0 1px green;
}

.submit-button {
    border-color: #50C878;
    background-color: white;
    font-weight: bold;
    color: #2E2E2E;
    margin-left: 8px;
    margin-bottom: 16px;
}

.handle {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 16px;
    margin-top: 8px;
}

.handle-edit {
    width: 85%;
}

.name-search {
    font-size: 15px;
    padding-left: 8px;
}

.handle-search {
    font-size: 12px;
    color: grey;
    padding-left: 8px;
}

.tooltip {
    z-index: 2000;
}